@tailwind components;

@layer components {
  .am5exporting-icon {
    @apply !m-0 !p-0;
  }
  a.am5exporting-icon {
    @apply w-min h-min border border-neutral-border-3 rounded-lg relative;
  }

  .am5exporting-list {
    @apply absolute absolute bg-primary-surface-2 rounded-lg w-min;
  }

  .am5exporting-item {
    @apply bg-primary-surface-2 text-sm;
  }

  .am5exporting-item:hover {
    @apply bg-primary-surface-2;
  }

  a.am5exporting-icon:hover {
    @apply bg-primary-surface-2;
  }
}
