body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-family: Inter, sans;
}

@tailwind base;
@tailwind utilities;

@import 'rc-picker/assets/index.css';
@import './components/atoms/date-picker/style.css';
@import './components/table/style.css';
@import './styles/export.css';
@import './styles/scrollbar.css';
@import './styles/input.css';

@tailwind components;

@layer components {
  #floating-ui-root > div {
    @apply !z-[9997];
  }
}
