@tailwind components;

@layer components {
  .cd-date-picker {
    @apply border-none;
  }

  .cd-date-picker .rc-picker-input {
    @apply border border-neutral-border-2 py-2 px-4 rounded-md flex items-center;
  }

  .cd-date-picker .rc-picker-input:focus-within {
    @apply outline outline-primary-border;
  }

  .cd-date-picker .rc-picker-input input:focus {
    @apply outline-none;
  }

  .cd-date-picker .rc-picker-input .rc-picker-clear {
    @apply h-full flex flex-col justify-center;
  }

  .cd-date-picker__dropdown {
    @apply shadow-none z-[9999];
  }

  .cd-date-picker__dropdown .rc-picker-panel {
    @apply bg-neutral-white border border-neutral-border-2 rounded-lg outline-none;
  }

  .cd-date-picker__dropdown .rc-picker-header button {
    @apply p-1;
  }

  .cd-date-picker__dropdown .rc-picker-cell .rc-picker-cell-inner {
    @apply h-8 w-8 rounded-lg leading-8;
  }

  .cd-date-picker__dropdown .rc-picker-cell .rc-picker-cell-inner:hover {
    @apply bg-secondary-surface;
  }

  .cd-date-picker__dropdown .rc-picker-cell-selected .rc-picker-cell-inner {
    @apply bg-primary-border;
  }
}
