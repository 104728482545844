@tailwind components;

@layer components {
  ::-webkit-scrollbar {
    @apply w-[12px] h-[12px];
  }

  ::-webkit-scrollbar-track {
    @apply rounded bg-neutral-border-1;
  }

  *::-webkit-scrollbar-thumb {
    @apply rounded h-[16px] bg-neutral-border-3 rotate-45;
  }
}
