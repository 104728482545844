@tailwind components;

@layer components {
  .cascade-table {
    @apply border border-neutral-border-2 rounded-lg overflow-y-hidden overflow-x-auto;
  }
  .cascade-table > table {
    @apply border-collapse w-full;
  }
  .cascade-table > table > thead > tr > th,
  .cascade-table > table > thead > tr > td {
    @apply text-neutral-border-3 text-sm bg-neutral-surface-1 p-3 border-b border-r border-neutral-border-2;
  }
  .cascade-table > table > tfoot > tr > th,
  .cascade-table > table > tfoot > tr > td {
    @apply text-neutral-border-3 text-sm bg-neutral-surface-1 p-3 border-t border-r border-neutral-border-2 font-semibold;
  }
  .cascade-table > table > thead > tr > th > *,
  .cascade-table > table > thead > tr > td > *,
  .cascade-table > table > tfoot > tr > th > *,
  .cascade-table > table > tfoot > tr > td > * {
    @apply inline-block;
  }
  .cascade-table > table > thead > tr > th:last-child,
  .cascade-table > table > thead > tr > td:last-child,
  .cascade-table > table > tfoot > tr > th:last-child,
  .cascade-table > table > tfoot > tr > td:last-child {
    @apply border-r-0;
  }
  .cascade-table > table > tbody > tr > td {
    @apply border-r border-b border-neutral-border-2 p-3 text-sm text-neutral-subtitle-2 bg-neutral-white;
  }
  .cascade-table > table > tbody > tr:last-child > td {
    @apply border-b-0;
  }
  .cascade-table:not(.nohover) > table > tbody > tr:hover > td {
    @apply !bg-primary-surface-2 cursor-pointer;
  }
  .cascade-table > table > tbody > tr > td:last-child {
    @apply border-r-0;
  }
  .cascade-table .icon-opened {
    @apply text-primary-main;
  }
  .cascade-table > table > tbody > tr.row-expandable > td {
    @apply bg-primary-surface-1;
  }
  .cascade-table.secondary:not(.nohover) > table > tbody > tr:hover > td {
    @apply !bg-secondary-surface;
  }
  .cascade-table.secondary .icon-opened {
    @apply text-secondary-main;
  }
  .cascade-table.secondary > table > tbody > tr.row-expandable > td {
    @apply bg-secondary-surface;
  }
}
