@tailwind components;

@layer components {
  .hide-arrow input[type='number']::-webkit-outer-spin-button,
  .hide-arrow input[type='number']::-webkit-inner-spin-button,
  .hide-arrow input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}
